// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-templates-author-index-jsx": () => import("./../../../src/templates/Author/index.jsx" /* webpackChunkName: "component---src-templates-author-index-jsx" */),
  "component---src-templates-category-category-page-jsx": () => import("./../../../src/templates/category/CategoryPage.jsx" /* webpackChunkName: "component---src-templates-category-category-page-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-single-page-jsx": () => import("./../../../src/templates/single/Page.jsx" /* webpackChunkName: "component---src-templates-single-page-jsx" */),
  "component---src-templates-single-post-jsx": () => import("./../../../src/templates/single/Post.jsx" /* webpackChunkName: "component---src-templates-single-post-jsx" */)
}

